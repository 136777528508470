.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    z-index: 10;

    .modal {
        padding: 15px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: rgba(142, 211, 243, 0.5);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        backdrop-filter: blur(5px);

        .title{
            font-size: 20px;
            text-align: center;
        }

        .close_icon_container{
            position: absolute;
            cursor: pointer;
            right: 5px;
            top: 5px;
        }

        .info_container{
            border: 1px solid lightgray;
            width: fit-content;
            text-align: start;
            padding: 10px;
            border-radius: 8px;

            p{
                color: white;
                font-size: 15px;
                font-weight: 400;

                span{
                    color: black;
                    font-weight: 600;
                }
            }
        }

        p{
            margin-left: 30px;
            margin-right: 30px;
            font-size: 17px;
            color: black;
            font-weight: 600;
        }

        .input_container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .item{
                display: flex;
                flex-direction: column;
                gap: 5px;
                color: lightgray;
                font-weight: 600;
                text-align: start;
            }

            input{
                color: black;
            }
        }
    }
}