.video_item_container {
    background-color: white;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(50px);
    height: fit-content;
    width: fit-content;

    .container {
        color: black;
        font-size: 15px;
        white-space: nowrap;
        text-align: start;

        .video_item {
            display: flex;
            gap: 15px;
            align-items: center;

            img {
                border-radius: 8px;
            }

            .title {
                font-weight: 600;
                cursor: pointer;
            }

            .other {
                font-size: 14px;

                span {
                    font-weight: 600;
                }
            }

            button {
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
                background: rgba(255, 255, 255, 0.1);
                border-radius: 8px;
                backdrop-filter: blur(50px);
                height: fit-content;
                color: black;
                font-weight: 600;

                &:hover{
                    background-color: rgb(255, 0, 0, 0.7);
                    color: white;
                    border-color: white;
                }
            }
        }
    }
}