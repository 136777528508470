.container {
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    min-height: 100vh;
    max-height: fit-content;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(10px);

    .inputs_container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .editor_container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        button {
            width: fit-content;
        }

        .editor_input {
            margin-top: 25px;
            height: 50%;
            color: black;
        }
    }

    .sections_container {
        margin-top: 25px;
        display: flex;
        align-items: center;
        gap: 15px;

        .item {
            background-color: lightblue;
            color: black;
            cursor: pointer;
            padding: 5px 15px;
            border-radius: 16px;
            font-size: 13px;
            text-align: center;
            -webkit-box-shadow: 4px 4px 8px -2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -2px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -2px rgba(34, 60, 80, 0.2);
            transition-duration: 300ms;

            &:hover {
                background-color: whitesmoke;
                color: black;
            }

            &.active {
                background-color: whitesmoke;
                color: black;
            }
        }
    }
}