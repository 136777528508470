.modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 9px;

    .modal_container {
        padding: 15px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: rgba(142, 211, 243, 0.5);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        backdrop-filter: blur(5px);

        .close_icon_container{
            position: absolute;
            cursor: pointer;
            right: 5px;
            top: 5px;
        }

        p{
            margin-left: 30px;
            margin-right: 30px;
            font-size: 17px;
            color: black;
            font-weight: 600;
        }

        .input_container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            input{
                color: black;
            }
        }
    }
}