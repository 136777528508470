.header_container {
    height: fit-content;
    width: 100%;
    background-color: black;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(100px);

    .items_container {
        padding: 10px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .logo {
            width: 40px;
            height: 40px;
            background-color: black;
        }
    
        .account_info{
            width: fit-content;
            display: flex;
            gap: 10px;
            align-items: center;

            .user_nick{
                text-align: end;
                font-weight: 600;
                color: black;
            }
        
            .user_id{
                color: #7a7a7a;
                font-weight: 400;
            }
        }
    }
}