.track_container {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    padding: 15px;
    height: 90vh;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(10px);

    .header {
        width: fit-content;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        h1 {
            color: black;
        }

        p {
            text-align: start;
            color: whitesmoke;
            background-color: black;
            font-size: 15px;
            padding: 3px 6px;
            border-radius: 8px;
            font-weight: 600;
        }

        .tracks_count_container {
            display: flex;
            gap: 15px;
            align-items: center;
        }
    }

    .search_container {
        margin-top: 25px;
        display: flex;
        align-items: center;
    
        .input_search_container{
            display: flex;
            align-items: center;
            gap: 25PX;
        }
    }

    .tracks_container {
        margin-top: 25px;
        row-gap: 15px;
        column-gap: 25px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .search_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}