@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: white;
  background-image: linear-gradient(to right top, #3166b3, #3589c8, #54abd8, #80cce5, #b1ebf4);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: 0;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

blockquote{
  color: #666;
  margin: 0;
  padding-left: 10px;
  border-left: 3px lightgray solid;
}

*,h1, h2, h3, h4, a, p, button, input, div {
  /* text-decoration: none; */
  text-align: start;
  font-family: 'JetBrains Mono', monospace;
}

.spin{
  color: #ffa600;
  position: absolute;
  top: 50%;
  left: 50%;
}
