.container {
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    min-height: 100vh;
    max-height: fit-content;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(10px);

    .post_container {
        
        .header {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin: 25px 0px;
                max-height: 300px;
                border-radius: 16px;
                max-width: 100%;
            }

            h1 {
                text-align: center;
                text-align: start;
                color: gray;
            }
        }

        .text_body {
            text-align: start;
            margin-left: auto;
            margin-right: auto;
            max-width: 800px;
            color: black;
        }
    }

}