.custom_player_container {
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 248, 255, 0.5);
    padding: 5px 10px;
    border-radius: 8px;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: 10px;

    .custom_track_header {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: 5px;

        p {
            color: black;
            font-size: 13px;
            text-overflow: clip;
        }
    }

    .volume_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}