.container {
    padding: 15px 25px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    gap: 15px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

    @media (max-width: 550px) {
        padding: 10px 20px;
    }

    .icon {
        color: white;
        width: 40px;
        height: 40px;

        @media (max-width: 550px) {
            width: 30px;
            height: 30px;
        }
    }

    .info_container {

        .flex_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;

            @media (max-width: 550px) {
                gap: 15px;
            }

            .time {
                color: gray;
                font-size: 12px;
            }
        }

        .title {
            font-weight: 600;
            font-size: 17px;
            white-space: nowrap;
            color: white;

            span {
                color: black;
            }

            @media (max-width: 550px) {
                font-size: 15px;
            }
        }

        .desc {
            max-width: 350px;
            font-size: 13px;
            color: gray;

            @media (max-width: 550px) {
                font-size: 12px;
            }

            span {
                color: black;
                font-weight: 600;
            }
        }
    }

    .status {
        background-color: lightskyblue;
        color: white;
        padding: 5px 10px;
        font-size: 11px;
        white-space: nowrap;
        border-radius: 16px;
        filter: drop-shadow(0 0 5px lightskyblue);

        @media (max-width: 550px) {
            font-size: 10px;
        }

        &.complete {
            background-color: rgb(105, 240, 105);
            filter: drop-shadow(0 0 5px rgb(105, 240, 105));
            color: black;
        }

        &.denided {
            background-color: rgb(248, 65, 65);
            filter: drop-shadow(0 0 5px rgb(248, 65, 65));
            color: white;
        }
    }

    .btn_container {
        display: flex;
        gap: 15px;
        margin-left: auto;

        button {
            height: fit-content;
            font-size: 11px;
            border-radius: 16px;
            background-color: lightgray;
            transition-duration: 300ms;

            &.denided {
                &:hover {
                    background-color: red;
                    color: white !important;
                    border: 1px solid red !important;
                }
            }

            &.approve {
                &:hover {
                    background-color: lightgreen;
                    color: black !important;
                    border: 1px solid lightgreen !important;
                }
            }
        }
    }
}