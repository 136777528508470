.main_layout_component{
    min-height: 100vh;
    max-height: fit-content;

    .main_img{
        position: absolute;
        z-index: -1;
    }

    .other_container{
        margin-left: auto;
        margin-right: auto;
        max-width: 1240px;
        margin-top: 25px;
        display: flex;
        gap: 25px;
    }
}