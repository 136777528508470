.user_item_main {
    background-color: white;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(50px);
    height: fit-content;
    min-width: fit-content;
    width: fit-content;
    
    .user_item_container {
        display: flex;
        min-width: fit-content;
        height: fit-content;
        align-items: center;
        border-radius: 8px;
        gap: 15px;
        justify-content: space-between;

        .user_item_info {
            gap: 10px;
            display: flex;

            .nick {
                color: black;
                font-weight: 600;
                text-align: start;
                cursor: pointer;
            }

            .id {
                color: rgb(30, 30, 30);
                font-weight: 600;
                font-size: 10px;
                text-align: start;
            }
        }

        .status {
            color: white;
            background-color: black;
            font-size: 10px;
            padding: 5px 10px;
            border-radius: 8px;
        }

        .admin_status {
            color: black;
            font-weight: 600;
            background-color: gold;
            font-size: 10px;
            padding: 5px 10px;
            border-radius: 8px;
        }
    }

    .user_item_btn_container {
        display: flex;
        gap: 10px;

        button {
            font-size: 13px;
            height: fit-content;
            border-radius: 8px;
        }
    }


    .referral_container{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}