.menu_container{
    width: fit-content;
    padding: 15px;
    background-color: #3B3635;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    font-weight: 500;
    color: black;
    backdrop-filter: blur(10px);
}