.container {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    padding: 15px;
    height: 90vh;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(10px);

    .infos_container {
        margin-top: 25px;

        .basic_info {
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
            background: rgba(255, 255, 255, 0.1);
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 8px;
            backdrop-filter: blur(10px);
            text-align: start;
            width: fit-content;
            padding: 10px 15px;
        
            p{
                color: white;
                font-family: 500;
                span{
                    color: black;
                    font-weight: 600;
                }
            }
        } 
    }
}