.track_item_main {
    background-color: white;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    backdrop-filter: blur(50px);
    height: fit-content;
    cursor: pointer;
    transition-duration: 300ms;

    &:hover{
        margin-top: 10px;
    }

    .track_item_container {
        display: flex;
        width: fit-content;
        height: fit-content;
        align-items: center;
        border-radius: 8px;
        gap: 15px;

        .track_delete_container{
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
        }

        .track_item_info {
            gap: 10px;
            display: flex;
            align-items: center;

            .name {
                color: black;
                font-weight: 600;
                text-align: start;
            }

            .other {
                color: black;
                font-weight: 400;
                font-size: 12px;
                text-align: start;
            }
        
            .code{
                color: black;
                font-weight: 600;
                font-size: 13px;
                cursor: pointer;
                text-align: start;
                font-weight: 600;
            }
        }

    }

    .player_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}