.container {
    width: fit-content;
    height: fit-content;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    backdrop-filter: blur(10px);

    .preview {
        display: flex;
        align-items: center;

        img {
            max-width: 300px;
        }
    }

    .title {
        font-weight: 700;
        color: black;
    }

    .info_container {
        padding: 10px 25px;
        //background-color: white;
    }

    .post_info_container {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .date {
            color: rgb(172, 172, 172);
            font-size: 10px;
        }

        .likes_count {
            display: flex;
            gap: 5px;
            align-items: center;
            color: rgb(172, 172, 172);
            font-size: 14px;

            .count {
                font-weight: 600;
            }
        }
    }

    .post_section {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .section {
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 8px;
            width: fit-content;
            background-color: lightskyblue;
            -webkit-box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
        }
    }
}