.auth_container {
    width: 30%;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;

    .form_container {
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        padding: 25px;
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        gap: 25px;

        .header {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h1 {
                color: black;
            }

            p {
                color: gray;
                font-weight: 600;
            }
        }
    }
}