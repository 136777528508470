.container{
    background-color: rgba(255, 255, 255, 0.537);
    width: 100%;
    cursor: pointer;
    border-radius: 16px;
    padding: 10px 15px;

    .referral_container{
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: start;

        .title{
            font-weight: 600;
            color: black;
        }
    
        .desc{
            color: gray;
            font-size: 12px;
        }
    }
}